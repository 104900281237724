import React, {useState} from 'react';
import {Grid, Typography, TextField, FormControl, MenuItem, makeStyles} from '@material-ui/core';

// local imports
import UploadComponent from '../../utils/UploadComponent';
import Button from "../common/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(4, 1, 1, 1),
    minWidth: '300px'
  },
  serviceTitle: {
    margin: theme.spacing(4, 1, 0, 1)
  },
  uploadComponent: {
    color: "red",
    fontSize: "15px"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  imageThumbnail: {
    maxWidth: "200px",
    height: "auto",
    margin: "10px"
  },
  thumbnailRow: {
    display: "inline-flex",
     margin: "30px"
  }
}))

const AdditionalBookingDetails = ({
                                    formField: { customerMessage, serviceType },
                                    formProps,
                                    setFieldValue
                                  }) => {
  const classes = useStyles();
  const [imageURLs, setImageURLs] = useState([]);

  const appendImageURLs = (newImageURLs) => {
    setImageURLs(newImageURLs)
  }

  const appendImages = (newImages) => {
    const allImages = [...formProps.values.images, ...newImages]
    setFieldValue('images',  [...formProps.values.images, ...newImages])
    const newImageURLs = [];
    allImages.forEach((image, index) => {
      newImageURLs[index] = URL.createObjectURL(image);
    })
    appendImageURLs(newImageURLs)
  }

  const clearImages = () => {
    setFieldValue('images',  [])
  };

  const numberOfPicturesPerRow = 3

  // an array of rows, each of which is going to be an array of images
  const imageRows = []

  // iterate over all images, and assign them to each row
  if (formProps.values.images && formProps.values.images.length > 0) {
    imageRows.push([])
    const allImages = formProps.values.images
    let currentRowCount = 0;
    let imageCount = 0
    while (imageCount < allImages.length) {
      if (currentRowCount >= numberOfPicturesPerRow) {
        // Need a new row
        imageRows.push([])
        currentRowCount = 0
      }
      imageRows.at(-1).push(allImages[imageCount])
      imageCount += 1
      currentRowCount += 1
    }
  }

  return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Job Information
            </Typography>

            <Grid container justify="center">
              <FormControl className={classes.formControl}>
                <TextField
                    select
                    label=" Please select a type of service:"
                    name={serviceType.name}
                    value={formProps.values.serviceType}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                        formProps.touched.serviceType &&
                        Boolean(formProps.errors.serviceType)
                    }
                    helperText={
                        formProps.touched.serviceType && formProps.errors.serviceType
                    }>
                  <MenuItem value={0}>Plumbing</MenuItem>
                  <MenuItem value={1}>Gas</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                    name={customerMessage.name}
                    multiline
                    fullWidth
                    variant="outlined"
                    rows={4}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values.customerMessage}
                    error={
                        formProps.touched.customerMessage &&
                        Boolean(formProps.errors.customerMessage)
                    }
                    helperText={
                        formProps.touched.customerMessage &&
                        formProps.errors.customerMessage
                    }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Images
                </Typography>
                <UploadComponent setFieldValue={appendImages} form/>
                {(formProps.touched.images && Boolean(formProps.errors.images)) &&
                    <p className={classes.uploadComponent}>{formProps.errors.images}</p>
                }
                {imageRows.map((row, rowIndex) => (
                    <Grid key={`thumbnail-row-${rowIndex * 6}`} className={classes.thumbnailRow}>
                      {row.map((file, index) => (
                              <Grid key={file.name} item>
                                <Typography key={file.name}>{file.name}</Typography>
                                <img src={imageURLs[rowIndex * numberOfPicturesPerRow + index]} alt={file.name} className={classes.imageThumbnail}/>
                              </Grid>
                          ))}
                    </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                      disabled={!formProps.values.images || formProps.values.images.length === 0 }
                      variant="contained"
                      color="accent"
                      className={classes.button}
                      onClick={clearImages}
                  >
                    Clear Images
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
}

export default AdditionalBookingDetails;
